export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Soccer Player Academy',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    "Welcome to Soccer Player Academy! You've just joined an ambitious community dedicated to not only improving soccer skills but also instilling a lifelong passion for the game. Our personalized coaching, cutting-edge facilities, and supportive environment are all geared toward helping you achieve your soccer dreams.",
  'client_theme.ThemeSidebar.mission1':
    'Get ready to elevate your game, embrace teamwork, and celebrate every victory. Here, your journey to soccer excellence begins.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
};
