export default {
  name: 'Train What Matters',
  siteURL: 'https://www.soccerplayeracademy.com/',
  logo: {
    url: 'https://s3.amazonaws.com/upperhand-app/static/images/soccer_player_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#000000',
    mobileSignInColor: '#000000',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#e6c453',
    accent1Color: '#660b08',

    primary: {
      main: '#e6c453',
    },
    secondary: {
      main: '#660b08',
    },
  },
  sideNav: {
    selectedColor: '#e6c453',
  },
  icons: {
    cart: '#e6c453',
  },
  statusBar: {
    backgroundColor: '#e6c453',
  },
};
